import React from 'react';
import { useSelector } from 'react-redux';
import { Header, Divider, Text, View, Flex, ActionButton } from '@adobe/react-spectrum';
import './FooterBar.css';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getId, getInstallations } from 'firebase/installations';

export function FooterBar() {
    const listItems = useSelector((state) => state?.leaderboard?.listItems);
    const deckCount = useSelector((state) => state?.app?.stats?.totalCount | 0);
    const metaIngestDate = useSelector((state) => state?.app?.stats?.metaIndexDate);
    const route = useSelector((state) => state?.app?.route);

    let deckCountString = ``;
    console.log(`route => ${JSON.stringify(route, null, 4)}`);

    if ((route?.path === '/' || route?.path === '') && window.location.pathname === '/') {
        deckCountString = `${listItems.length} / ${deckCount} salty ass decks...`;
    }

    const handleDiscordLink = async () => {
        logEvent(getAnalytics(), 'bot_click');
        window.open(
            `https://discord.com/api/oauth2/authorize?client_id=1130881153998540800&permissions=2147483648&scope=bot`,
            `_blank`
        );
    };

    let timeStamp = '...';
    if (metaIngestDate) {
        const ingestDate = new Date(metaIngestDate);
        timeStamp = `${ingestDate.getMonth() + 1}.${ingestDate.getDate()}.${ingestDate.getFullYear()}`;
    } else {
        timeStamp = '08.03.24';
    }

    return (
        <View
            width="100%"
            UNSAFE_style={{
                position: 'fixed',
                bottom: '0',
                backgroundColor: '#1e1e1e',
                height: '30px',
                zIndex: '400',
            }}
        >
            <Divider size="M" width="100%" />
            {/*<span style={{ marginRight: '5px', alignSelf: 'right' }}>*/}
            {/*    <ActionButton*/}
            {/*        UNSAFE_className="footerDiscordLink"*/}
            {/*        onPress={handleDiscordLink}*/}
            {/*        aria-label="Join our Discord!"*/}
            {/*        isQuiet="true"*/}
            {/*    >*/}
            {/*        Check out our Discord bot!*/}
            {/*    </ActionButton>*/}
            {/*</span>*/}

            <Flex direction={'row'} justifyContent={'space-around'} width={'100%'}>
                <div>
                    <Text UNSAFE_className="footerText">cEDH meta refreshed: {timeStamp}</Text>
                </div>
                <div style={{ width: '100%' }} />
                <div>
                    <Text UNSAFE_className="footerText">{deckCountString}</Text>
                </div>
            </Flex>
            {/*</Header>*/}
        </View>
    );
}
