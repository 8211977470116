/* eslint-disable no-loop-func */
import { toast } from 'react-toastify';
import { getAnalytics, logEvent } from 'firebase/analytics';
import store from './redux/store/AppStore';
import { SORT_MAP } from './redux/slices/leaderboardSlice';

export const getDomainPrefix = () => {
    const href = window.location.href;

    if (href.includes(`localhost`)) {
        if (href.includes(`useStaging=true`)) {
            return `https://staging-api.commandersalt.com`;
        }

        return `http://localhost:3333`;
    } else if (href.includes(`192.168`)) {
        return `http://192.168.1.27:3333`;
    } else if (href.includes(`staging`)) {
        return `https://staging-api.commandersalt.com`;
    }

    return `https://api.commandersalt.com`;
};

let isFetchingPreviewDeck = false;

export const DynamoConnector = {
    getBatchRefreshAuthorDeckList: async (list, callback) => {
        const promises = [];
        list.forEach((item) => {
            let fetchUri = `${getDomainPrefix()}/details?id=${item.id}`;
            promises.push(
                fetch(fetchUri, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                    },
                })
            );
        });

        await Promise.all(promises);
        callback && callback();
    },
    getStats: async (callback) => {
        let fetchUri = `${getDomainPrefix()}/stats`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getDeck: async (id, callback, errorCallback) => {
        try {
            const deck = store.getState().preview.cache?.[id];
            // console.log(JSON.stringify(deck, null, 4));
            if (deck) {
                // console.log(`==> returning cached deck id ${id}`);
                callback(deck);

                // bail early
                return;
            }

            if (isFetchingPreviewDeck) {
                return null;
            }

            isFetchingPreviewDeck = true;
            let fetchUri = `${getDomainPrefix()}/details?id=${id}`;

            const results = await (
                await fetch(fetchUri, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                    },
                })
            ).json();

            isFetchingPreviewDeck = false;
            callback && callback(results);
        } catch (error) {
            isFetchingPreviewDeck = false;
            errorCallback && errorCallback();
        }
    },
    getDeckForPreview: async (id, callback, errorCallback) => {
        try {
            const deck = store.getState().preview.cache?.[id];
            // console.log(JSON.stringify(deck, null, 4));
            if (deck) {
                // console.log(`==> returning cached deck id ${id}`);
                callback(deck);

                // bail early
                return;
            }

            if (isFetchingPreviewDeck) {
                return null;
            }

            isFetchingPreviewDeck = true;
            let fetchUri = `${getDomainPrefix()}/details?id=${id}`;

            const results = await (
                await fetch(fetchUri, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json;charset=UTF-8',
                    },
                })
            ).json();

            isFetchingPreviewDeck = false;
            callback(results);
        } catch (error) {
            isFetchingPreviewDeck = false;
            errorCallback && errorCallback();
        }
    },
    getCardDetails: async (id, callback) => {
        let fetchUri = `${getDomainPrefix()}/details?id=${id?.replace(/-/g, '_')}&isCard=true`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getCommanderDetails: async (id, callback) => {
        let fetchUri = `${getDomainPrefix()}/details?id=${id?.replace(/-/g, '_')}&isCommander=true`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getAuthorDetails: async (id, callback) => {
        let fetchUri = `${getDomainPrefix()}/details?id=${id}&isAuthor=true`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getCEDHMeta: async (callback) => {
        let fetchUri = `${getDomainPrefix()}/meta`;

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getDeckUpgrades: async (
        id,
        category,
        allowReservedList = true,
        colorFilters = null,
        selectedTypes = [],
        cursor,
        callback
    ) => {
        let fetchUri = `${getDomainPrefix()}/upgrades?id=${id}&category=${category}&allowReservedList=${allowReservedList}`;

        if (colorFilters) {
            fetchUri = `${fetchUri}&colorFilters=${colorFilters}`;
        }

        if (selectedTypes.length > 0) {
            fetchUri = `${fetchUri}&selectedTypes=${JSON.stringify(selectedTypes)}`;
        }

        if (cursor) {
            cursor = encodeURIComponent(cursor);
            fetchUri = `${fetchUri}&cursor=${cursor}`;
        }

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getList: async (cursor, category, index = null, filters, direction = SORT_MAP.DIRECTION.DESC, callback) => {
        let fetchUri = `${getDomainPrefix()}/list?category=${category}`;

        if (index === null) {
            index = SORT_MAP.POWERLEVEL.index;
        }

        // console.log(`=> ${JSON.stringify(direction)}`);
        const directionPrefix = direction === SORT_MAP.DIRECTION.DESC ? '' : '-';

        fetchUri = `${fetchUri}&sort=${directionPrefix}${index}`;

        if (cursor) {
            cursor = encodeURIComponent(cursor);
            fetchUri = `${fetchUri}&cursor=${cursor}`;
        }

        if (filters) {
            if (filters?.colorFilters) {
                fetchUri = `${fetchUri}&isFiltered=true`;
                fetchUri = `${fetchUri}&colorIdentity=${filters.colorFilters}`;
            }

            if (filters?.range) {
                fetchUri = `${fetchUri}&high=${filters.range.high}&low=${filters.range.low}`;
            }

            if (filters?.query && filters?.queryType) {
                fetchUri = `${fetchUri}&query=${filters.query}&queryType=${filters.queryType}`;
            }

            if (filters?.id) {
                fetchUri = `${fetchUri}&id=${filters.id}`;
            }

            if (filters.archetypes) {
                fetchUri = `${fetchUri}&archetypes=${filters.archetypes}`;
            }
        }

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    getShallowList: async (query, category, index = null, callback) => {
        let fetchUri = `${getDomainPrefix()}/list/shallow?category=${category}&id=${query}`;

        if (index) {
            fetchUri = `${fetchUri}&index=${index}`;
        }

        const results = await (
            await fetch(fetchUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            })
        ).json();

        callback(results);
    },
    // async (cursor, filters, sortBy, callback) => {
    //     const analytics = getAnalytics();
    //     let fetchUri = `${getDomainPrefix()}/leaderboard`;
    //     fetchUri = `${fetchUri}?index=${sortBy.index}`;
    //
    //     if (filters?.colorFilters) {
    //         fetchUri = `${fetchUri}&colors=${filters.colorFilters}`;
    //         fetchUri = `${fetchUri}&exact=${filters.colorFiltersExact}`;
    //     }
    //
    //     if (filters?.range?.high && filters?.range?.low) {
    //         fetchUri = `${fetchUri}&high=${filters.range.high}&low=${filters.range.low}`;
    //     }
    //
    //     if (cursor) {
    //         cursor = encodeURIComponent(cursor);
    //         fetchUri = `${fetchUri}&cursor=${cursor}`;
    //     }
    //
    //     // // if (filters?.isFiltered) {
    //     // if (!cursor) {
    //     //     fetchUri = `${fetchUri}?`;
    //     // } else {
    //     //     fetchUri = `${fetchUri}&`;
    //     // }
    //
    //     fetchUri = `${fetchUri}&sources=${encodeURIComponent(filters?.sources?.toString())}`;
    //
    //     if (filters?.query) {
    //         logEvent(analytics, 'search', { query: filters.query });
    //         fetchUri = `${fetchUri}&query=${encodeURIComponent(filters?.query)}`;
    //     }
    //
    //     const results = await (
    //         await fetch(fetchUri, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-type': 'application/json;charset=UTF-8',
    //             },
    //         })
    //     ).json();
    //
    //     results.items = results?.items?.map((item) => {
    //         if (item?.data) {
    //             return {
    //                 ...item,
    //                 ...item.data,
    //             };
    //         }
    //
    //         return item;
    //     });
    //
    //     callback(results);
    // },
    addAuthorToPod: async (authorId, podId, doneCallback, errorCallback) => {
        const analytics = getAnalytics();

        try {
            const request = await fetch(`${getDomainPrefix()}/pods/authors/add?podId=${podId}&authorId=${authorId}`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            });
            const response = await request.json();

            if (request.status !== 200) {
                throw response;
            }

            // logEvent(analytics, 'pod_add_author_success', { podId, authorId });

            doneCallback({
                ...response?.pod,
                key: response?.pod?.id,
            });
        } catch (error) {
            logEvent(analytics, 'pod_add_author_fail', { podId, authorId });
            errorCallback(error);
            toast(`${error.message}`);
        }
    },
    importDeckList: async (url, statusCallback, doneCallback, errorCallback, isRefresh = false) => {
        const analytics = getAnalytics();

        let oldDeckId = 'sdlfksdf';
        if (window.location.pathname.includes(`/details/deck/`)) {
            oldDeckId = window.location.pathname.substring(window.location.pathname.indexOf(`/deck/`) + 6);
        }

        try {
            const startTime = performance.now();

            statusCallback({
                type: `card`,
                card: 'Loading...',
                percentage: 1,
            });

            let requestUri = `${getDomainPrefix()}/import?url=${url}`;
            if (oldDeckId && isRefresh) {
                requestUri = `${requestUri}&oldDeckId=${oldDeckId}`;
            }

            const request = await fetch(requestUri, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            });
            const response = await request.json();

            if (request.status !== 200) {
                throw response;
            }

            const endTime = performance.now();
            const responseTime = endTime - startTime;
            logEvent(getAnalytics(), 'import_time', { timing: responseTime });

            doneCallback({
                ...response.deck,
                key: response.deck.id,
            });

            // if (oldDeckId && response?.deck?.id && response?.deck?.id !== oldDeckId) {
            //     await fetch(`${getDomainPrefix()}/deletedeck?id=${oldDeckId}`, {
            //         method: 'GET',
            //         headers: {
            //             'Content-type': 'application/json;charset=UTF-8',
            //         },
            //     });
            // }
        } catch (error) {
            logEvent(analytics, 'import_failed', { url });
            errorCallback(error);
            toast(`${error.message}`);
        }
    },
    postContactPing: async (payload, callback) => {
        try {
            const email = encodeURIComponent(payload.email);
            const name = encodeURIComponent(payload.name);
            const message = encodeURIComponent(payload.message);

            const request = await fetch(`${getDomainPrefix()}/contact?email=${email}&name=${name}&message=${message}`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
            });

            const response = await request.json();

            if (request.status !== 200) {
                throw response;
            }
        } catch (error) {
            callback(); // TODO: add status to ui
            logEvent(analytics, 'email_failed', { payload });
        }

        logEvent(analytics, 'email_success', { payload });
        callback();
    },
};
