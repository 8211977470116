import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ActionButton, Flex, Text } from '@adobe/react-spectrum';
import './Algorithm.css';
import { Helmet } from 'react-helmet';
import isMobile from 'ismobilejs';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import ZoomIn from '@spectrum-icons/workflow/ZoomIn';
import ZoomOut from '@spectrum-icons/workflow/ZoomOut';
import Refresh from '@spectrum-icons/workflow/Refresh';

export function Algorithm() {
    const dispatch = useDispatch();

    const isFetching = useSelector((state) => state?.meta?.isFetching);
    const metaBlob = useSelector((state) => state?.meta?.metaBlob);
    const mobile = isMobile(window.navigator).phone;

    const helmetName = `Power Level Algorithm`;
    const helmetCanonical = `https://www.commandersalt.com/algorithm`;
    const helmetDescription = `Power Level Algorithm`;
    const helmetTitle = `Commandersalt.com - Power Level Algorithm`;

    let [tab, setTab] = React.useState('commanders');

    const handleTabSelectionChange = (evn) => {
        if (tab !== evn) {
            setTab(evn);

            logEvent(getAnalytics(), `meta_setTab_${evn.toUpperCase()}`);
        }

        // const tabList = document.getElementById('tabPanel');
        // const tabListTop = tabList.getBoundingClientRect().top;
        //
        // if (mobile) {
        //     window.scrollBy({ top: tabListTop, left: 0, behavior: 'smooth' });
        // }
    };

    const handleDownloadLinkPress = () => {
        logEvent(getAnalytics(), `download_algorithm_chart`);
    };
    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
            <>
                <Flex direction={'row'} columnGap={20}>
                    <ActionButton onPress={() => zoomIn()} isQuiet="true" UNSAFE_style={{ cursor: 'pointer' }}>
                        <ZoomIn />
                        Zoom In
                    </ActionButton>
                    <ActionButton onPress={() => zoomOut()} isQuiet="true" UNSAFE_style={{ cursor: 'pointer' }}>
                        <ZoomOut />
                        Zoom Out
                    </ActionButton>
                    <ActionButton onPress={() => resetTransform()} isQuiet="true" UNSAFE_style={{ cursor: 'pointer' }}>
                        <Refresh />
                        Reset
                    </ActionButton>
                </Flex>
            </>
        );
    };

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            <Flex margin={10} direction={'column'} wrap justifyContent={'space-around'} marginStart={20} marginEnd={20}>
                <Flex direction={'row'} justifyContent={'space-around'}>
                    <div style={{ maxWidth: 700 }}>
                        <Text>
                            I believe that a lot of people tend to think of the power scale as a linear graph from 1 -
                            10. However, I see it as more of a cubic graph: There is a large difference between 1 - 4,
                            but then it flattens out a bit from 4 - 6. Power level 7 is where it basically starts taking
                            off and quickly ramping up to cEDH levels. This is what the site is modeled after.
                            <p />
                            TLDR;&nbsp;
                            <i>
                                Everything is <b>not</b> a 7.
                            </i>
                            <p />
                            <img src="resources/power_level_graph.png" style={{ maxWidth: '100%', height: 'auto' }} />
                            <p />
                            <b>Please keep in mind that this is a living document.</b>
                            <p />
                            <a
                                style={{ color: '#fad104' }}
                                onClick={handleDownloadLinkPress}
                                href="/resources/commandersalt_algorithm.pdf"
                                download
                            >
                                PDF version available here.
                            </a>
                        </Text>
                    </div>
                </Flex>
                <Flex direction={'column'} marginTop={10}>
                    <TransformWrapper>
                        <Controls />
                        <TransformComponent>
                            <img src="/resources/commandersalt_algorithm.svg" alt="test" width="100%" />
                        </TransformComponent>
                    </TransformWrapper>
                </Flex>
            </Flex>
        </Flex>
    );
}
