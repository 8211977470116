import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ActionButton,
    Cell,
    Flex,
    Text,
    View,
    Tabs,
    TabList,
    Item,
    TabPanels,
    repeat,
    Grid,
} from '@adobe/react-spectrum';
import './PowerLevels.css';
import { Helmet } from 'react-helmet';
import isMobile from 'ismobilejs';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { setAppRoute } from '../../data/redux/slices/appSlice';

export function PowerLevels() {
    const dispatch = useDispatch();

    const isFetching = useSelector((state) => state?.meta?.isFetching);
    const metaBlob = useSelector((state) => state?.meta?.metaBlob);
    const mobile = isMobile(window.navigator).phone;

    const helmetName = `Power Levels`;
    const helmetCanonical = `https://www.commandersalt.com/powerlevels`;
    const helmetDescription = `Power Levels`;
    const helmetTitle = `Commandersalt.com - Power Levels`;

    const handleMetaClick = () => {
        logEvent(getAnalytics(), 'powerlevels_meta_click');
        dispatch(setAppRoute({ route: '/meta' }));
    };

    const handleAlgorithmClick = () => {
        logEvent(getAnalytics(), 'powerlevels_algorithm_click');
        dispatch(setAppRoute({ route: '/algorithm' }));
    };

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            <Flex margin={10} direction={'column'} wrap justifyContent={'space-around'} marginStart={20} marginEnd={20}>
                <Flex direction={'row'} justifyContent={'space-around'}>
                    <div style={{ maxWidth: 800 }} className={'powerLevelText'}>
                        <Text>
                            <span className={'powerLevelHeaderText'}>
                                Power level scoring is not an exact science, especially because there is no universally
                                accepted definition of scale. With that in mind, the logic used by the site to rank
                                decks is based on this set of criteria.
                            </span>
                            <div style={{ cursor: 'pointer' }} onClick={handleMetaClick}>
                                <Text
                                    UNSAFE_className={'powerLevelLinkText'}
                                    marginStart={10}
                                    top={7}
                                    position={'relative'}
                                >
                                    cEDH tiers and meta defined here
                                </Text>
                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={handleAlgorithmClick}>
                                <Text
                                    UNSAFE_className={'powerLevelLinkText'}
                                    marginStart={10}
                                    top={7}
                                    position={'relative'}
                                >
                                    Read about the scoring algorithm here
                                </Text>
                            </div>
                            <div style={{ height: '30px' }} />
                            <Flex direction={'column'} rowGap={10}>
                                <View backgroundColor={'gray-200'} padding={10}>
                                    <Flex
                                        direction={'row'}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <View
                                            minWidth={125}
                                            borderEndWidth={1}
                                            marginEnd={30}
                                            borderColor={'gray-300'}
                                            UNSAFE_style={{ backgroundColor: '#b7d147' }}
                                        >
                                            <Flex
                                                height={40}
                                                direction={'column'}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <span className={'powerLevelTextSectionLabel'}>1 - 4.99x</span>
                                            </Flex>
                                        </View>
                                        <View width={'100%'}>
                                            <span className={'powerLevelTextPrefix'}>
                                                Casual
                                                <br />
                                            </span>{' '}
                                            Precons and low power brews fall into this range.
                                        </View>
                                    </Flex>
                                </View>
                                <View backgroundColor={'gray-200'} padding={10}>
                                    <Flex
                                        direction={'row'}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <View
                                            minWidth={125}
                                            borderEndWidth={1}
                                            marginEnd={30}
                                            borderColor={'gray-300'}
                                            UNSAFE_style={{ backgroundColor: '#cdb140' }}
                                        >
                                            <Flex
                                                height={40}
                                                direction={'column'}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <span className={'powerLevelTextSectionLabel'}>5 - 6.49x</span>
                                            </Flex>
                                        </View>
                                        <View width={'100%'}>
                                            <span className={'powerLevelTextPrefix'}>
                                                Upgraded casual
                                                <br />
                                            </span>{' '}
                                            Upgraded precons and stronger brews show up here. Decks start to become more
                                            focused on a specific game plan.
                                        </View>
                                    </Flex>
                                </View>
                                <View backgroundColor={'gray-200'} padding={10}>
                                    <Flex
                                        direction={'row'}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <View
                                            minWidth={125}
                                            borderEndWidth={1}
                                            marginEnd={30}
                                            borderColor={'gray-300'}
                                            UNSAFE_style={{ backgroundColor: '#c5722e' }}
                                        >
                                            <Flex
                                                height={40}
                                                direction={'column'}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <span className={'powerLevelTextSectionLabel'}>6.5 - 7.59x</span>
                                            </Flex>
                                        </View>
                                        <View width={'100%'}>
                                            <span className={'powerLevelTextPrefix'}>
                                                Mid power
                                                <br />
                                            </span>{' '}
                                            Decks in this range are more optimized with a more focused gameplan, but
                                            have key things such as manabase quality which keeps them out of high power
                                            range. A lot of times this is the result of purposeful deck building
                                            considerations to keep the deck from being overpowered.
                                        </View>
                                    </Flex>
                                </View>
                                <View backgroundColor={'gray-200'} padding={10}>
                                    <Flex
                                        direction={'row'}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <View
                                            minWidth={125}
                                            borderEndWidth={1}
                                            marginEnd={30}
                                            borderColor={'gray-300'}
                                            UNSAFE_style={{ backgroundColor: '#c35428' }}
                                        >
                                            <Flex
                                                height={40}
                                                direction={'column'}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <span className={'powerLevelTextSectionLabel'}>7.6 - 8.99x</span>
                                            </Flex>
                                        </View>
                                        <View width={'100%'}>
                                            <span className={'powerLevelTextPrefix'}>
                                                High power
                                                <br />
                                            </span>{' '}
                                            These decks have a very focused path to winning, and mostly optimized in
                                            terms of manabase efficiency and overall speed of execution. Most of the
                                            time the thing keeping these decks out of fringe cEDH are budget-conscious
                                            deck building decisions.
                                        </View>
                                    </Flex>
                                </View>
                                <View backgroundColor={'gray-200'} padding={10}>
                                    <Flex
                                        direction={'row'}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <View
                                            minWidth={125}
                                            borderEndWidth={1}
                                            marginEnd={30}
                                            borderColor={'gray-300'}
                                            UNSAFE_style={{ backgroundColor: '#d13d29' }}
                                        >
                                            <Flex
                                                height={40}
                                                direction={'column'}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <span className={'powerLevelTextSectionLabel'}>9 - 9.29x</span>
                                            </Flex>
                                        </View>
                                        <View width={'100%'}>
                                            <span className={'powerLevelTextPrefix'}>
                                                Fringe cEDH and cEDH
                                                <br />
                                            </span>{' '}
                                            There are a bit of a mix of decks in this range. There are fringe cEDH
                                            decks, which run unproven commanders, as well as commanders that are in the
                                            meta but don't typically place well. You will also find lists running
                                            commanders who are higher tier meta wise, but built less efficiently than
                                            they could be.
                                        </View>
                                    </Flex>
                                </View>
                                <View backgroundColor={'gray-200'} padding={10}>
                                    <Flex
                                        direction={'row'}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <View
                                            minWidth={125}
                                            borderEndWidth={1}
                                            marginEnd={30}
                                            borderColor={'gray-300'}
                                            UNSAFE_style={{ backgroundColor: '#ea3323' }}
                                        >
                                            <Flex
                                                height={40}
                                                direction={'column'}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <span className={'powerLevelTextSectionLabel'}>9.3 - 10.29x</span>
                                            </Flex>
                                        </View>
                                        <View width={'100%'}>
                                            <span className={'powerLevelTextPrefix'}>
                                                cEDH - Tier 3<br />
                                            </span>{' '}
                                            These decks are running meta commanders which do not consistently place well
                                            in tournament results.
                                        </View>
                                    </Flex>
                                </View>
                                <View backgroundColor={'gray-200'} padding={10}>
                                    <Flex
                                        direction={'row'}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <View
                                            minWidth={125}
                                            borderEndWidth={1}
                                            marginEnd={30}
                                            borderColor={'gray-300'}
                                            UNSAFE_style={{ backgroundColor: '#ea3323' }}
                                        >
                                            <Flex
                                                height={40}
                                                direction={'column'}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <span className={'powerLevelTextSectionLabel'}>10.3 - 10.69x</span>
                                            </Flex>
                                        </View>
                                        <View width={'100%'}>
                                            <span className={'powerLevelTextPrefix'}>
                                                cEDH - Tier 2<br />
                                            </span>{' '}
                                            The commanders of these decks typically place in the low end of top 16
                                            results.
                                        </View>
                                    </Flex>
                                </View>
                                <View backgroundColor={'gray-200'} padding={10}>
                                    <Flex
                                        direction={'row'}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <View
                                            minWidth={125}
                                            borderEndWidth={1}
                                            marginEnd={30}
                                            borderColor={'gray-300'}
                                            UNSAFE_style={{ backgroundColor: '#ea3323' }}
                                        >
                                            <Flex
                                                height={40}
                                                direction={'column'}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <span className={'powerLevelTextSectionLabel'}>10.7 - 10.99x</span>
                                            </Flex>
                                        </View>
                                        <View width={'100%'}>
                                            <span className={'powerLevelTextPrefix'}>
                                                cEDH - Tier 1<br />
                                            </span>{' '}
                                            These are the best of the best. The sweatiest of the sweatiest. These
                                            commanders dominate the top 16 results of most cEDH tournaments.
                                        </View>
                                    </Flex>
                                </View>
                            </Flex>
                        </Text>
                    </div>
                </Flex>
            </Flex>
        </Flex>
    );
}
