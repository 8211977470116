import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ActionButton,
    Cell,
    Flex,
    Text,
    View,
    Tabs,
    TabList,
    Item,
    TabPanels,
    repeat,
    Grid,
} from '@adobe/react-spectrum';
import './Meta.css';
import { Helmet } from 'react-helmet';
import isMobile from 'ismobilejs';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import GraphBarVertical from '@spectrum-icons/workflow/GraphBarVertical';
import { PowerLevelPane } from '../deck/panes/PowerLevelPane';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { CommandersPane } from './panes/CommandersPane';
import { StaplesPane } from './panes/StaplesPane';

export function Meta() {
    const dispatch = useDispatch();

    const isFetching = useSelector((state) => state?.meta?.isFetching);
    const metaBlob = useSelector((state) => state?.meta?.metaBlob);
    const mobile = isMobile(window.navigator).phone;

    const helmetName = `cEDH Meta`;
    const helmetCanonical = `https://www.commandersalt.com/meta`;
    const helmetDescription = `cEDH Meta Commanders and Staples`;
    const helmetTitle = `Commandersalt.com - cEDH Meta Commanders and Staples`;

    let [tab, setTab] = React.useState('commanders');

    const handleTabSelectionChange = (evn) => {
        if (tab !== evn) {
            setTab(evn);

            logEvent(getAnalytics(), `meta_setTab_${evn.toUpperCase()}`);
        }

        // const tabList = document.getElementById('tabPanel');
        // const tabListTop = tabList.getBoundingClientRect().top;
        //
        // if (mobile) {
        //     window.scrollBy({ top: tabListTop, left: 0, behavior: 'smooth' });
        // }
    };

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            <Flex margin={10} direction={'column'} wrap justifyContent={'space-around'}>
                <Flex direction={'row'} justifyContent={'space-around'}>
                    <div style={{ maxWidth: 700 }}>
                        <Text>
                            Commandersalt uses the last three months worth of tournament reports from edhtop16.com to
                            determine which commanders are actually used for cEDH.
                            <p />
                            We break this list of commanders into 4 tiers; each tier has a power level cap as follows:
                            <ul>
                                <li>Tier 1: 10.99</li>
                                <li>Tier 2: 10.69</li>
                                <li>Tier 3: 10.29</li>
                            </ul>
                            Any decks with commanders not in one of these tiers is capped at 9.29.
                        </Text>
                    </div>
                </Flex>
                <Grid
                    columns={repeat('auto-fit', 400)}
                    gap={50}
                    justifyContent="center"
                    marginTop={30}
                    autoRows="size-1200"
                    marginBottom={20}
                >
                    <Flex direction={'column'} maxWidth={400}>
                        <div className={'commanderTiersTileStatisticsHeader'}>Decks and Staples</div>
                        <Text margin={10} marginBottom={0}>
                            Decks analyzed: <b>{metaBlob.parsedDeckCount}</b>
                        </Text>
                        <Text margin={10} marginBottom={0}>
                            Staples found: <b>{metaBlob.staplesCount}</b>
                        </Text>
                    </Flex>
                    <Flex direction={'column'} maxWidth={400}>
                        <div className={'commanderTiersTileStatisticsHeader'}>Events</div>
                        <Text margin={10} marginBottom={0}>
                            Events analyzed: <b>{metaBlob.eventCount}</b>
                        </Text>
                        <Text margin={10} marginBottom={0}>
                            Total number of top 16 slots: <b>{(metaBlob?.eventCount || 0) * 16}</b>
                        </Text>
                    </Flex>
                </Grid>
                <Tabs
                    selectedKey={tab}
                    isEmphasized
                    // UNSAFE_style={{ padding: 0 }}
                    onSelectionChange={handleTabSelectionChange}
                    id={'tabPanel'}
                >
                    <TabList marginStart="-5px" marginEnd="-5px" UNSAFE_className={'stickyTabBarContainer'}>
                        <Item key="commanders">
                            <GraphBarVertical size={'XS'} />
                            <Text UNSAFE_className="previewTabLabel">Commanders</Text>
                        </Item>
                        <Item key="staples">
                            <GraphBarVertical size={'XS'} />
                            <Text UNSAFE_className="previewTabLabel">Staples</Text>
                        </Item>
                    </TabList>
                    <TabPanels marginTop="10px" UNSAFE_style={{ paddingLeft: 10, paddingRight: 5 }}>
                        <Item key="commanders">
                            <CommandersPane metaBlob={metaBlob} />
                        </Item>
                        <Item key="staples">
                            <StaplesPane metaBlob={metaBlob} />
                        </Item>
                    </TabPanels>
                </Tabs>
            </Flex>
        </Flex>
    );
}
