import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, BrowserRouter, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Commanders } from './components/commanders/Commanders';
import { LeaderBoard } from './components/leaderboard/LeaderBoard';
import React from 'react';
import { Authors } from './components/authors/Authors';
import AppBody from './AppBody';
import { FAQ } from './components/faq/FAQ';
import { Changelog } from './components/changelog/Changelog';
// import { Pods } from './components/pods/Pods';
import ErrorBoundary from './components/ErrorBoundary';
import { Contact } from './components/contact/Contact';
import { CommanderDetails } from './components/commanders/CommanderDetails';
import { AuthorDetails } from './components/authors/AuthorDetails';
import { Menu, ProgressCircle } from '@adobe/react-spectrum';
import { Bot } from './components/bot/Bot';
import { Deck } from './components/deck/Deck';
import { Meta } from './components/meta/Meta';
import { Algorithm } from './components/algorithm/Algorithm';
import { Archetypes } from './components/archetypes/Archetypes';
import { Card } from './components/card/Card';
import { PowerLevels } from './components/powerlevels/PowerLevels';

export default function AppRouter() {
    const router = createBrowserRouter([
        {
            element: (
                <ErrorBoundary route={'*'}>
                    <AppBody />
                </ErrorBoundary>
            ),
            children: [
                {
                    path: '/',
                    element: (
                        <ErrorBoundary route={'/'}>
                            <LeaderBoard />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: '/bot',
                    element: (
                        <ErrorBoundary route={'/bot'}>
                            <Bot />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: '/details/*',
                    element: (
                        <ErrorBoundary route={'details/*'}>
                            <Deck />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'commanders',
                    element: (
                        <ErrorBoundary route={'commanders'}>
                            <Commanders />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'commanders/id/*',
                    element: (
                        <ErrorBoundary route={'commanders/id/*'}>
                            <CommanderDetails />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'archetypes',
                    element: (
                        <ErrorBoundary route={'archetypes'}>
                            <Archetypes />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'cards',
                    element: (
                        <ErrorBoundary route={'cards'}>
                            <Card />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'cards/id/*',
                    element: (
                        <ErrorBoundary route={'cards/id/*'}>
                            <Card />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'authors',
                    element: (
                        <ErrorBoundary route={'authors'}>
                            <Authors />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'authors/id/*',
                    element: (
                        <ErrorBoundary route={'authors/id/*'}>
                            <AuthorDetails />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'faq',
                    element: (
                        <ErrorBoundary route={'faq'}>
                            <FAQ />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'changelog',
                    element: (
                        <ErrorBoundary route={'changelog'}>
                            <Changelog />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'contact',
                    element: (
                        <ErrorBoundary route={'contact'}>
                            <Contact />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'meta',
                    element: (
                        <ErrorBoundary route={'meta'}>
                            <Meta />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'algorithm',
                    element: (
                        <ErrorBoundary route={'algorithm'}>
                            <Algorithm />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'powerlevels',
                    element: (
                        <ErrorBoundary route={'powerlevels'}>
                            <PowerLevels />
                        </ErrorBoundary>
                    ),
                },
                // {
                //     path: 'pods',
                //     element: (
                //         <ErrorBoundary route={'pods'}>
                //             <Pods />
                //         </ErrorBoundary>
                //     ),
                // },
                // {
                //     path: 'pods/id/*',
                //     element: (
                //         <ErrorBoundary route={'pods/id/*'}>
                //             <Pods />
                //         </ErrorBoundary>
                //     ),
                // },
            ],
        },
    ]);

    function Root() {
        return (
            <Routes>
                {/* ⬆️ Blog splat route lifted */}
                <Route path="/" element={<LeaderBoard />} />
            </Routes>
        );
    }

    function Details() {
        return (
            <Routes>
                {/* ⬆️ Blog index route lifted */}
                <Route path="details" element={<LeaderBoard />} />
            </Routes>
        );
    }

    return <RouterProvider router={router} fallbackElement={<ProgressCircle isIndeterminate="true" size="L" />} />;
}
